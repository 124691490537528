<template>
    <v-container v-if="productIds" >
        <v-row v-if="suggestedProductList.length > 0">
            <v-col class="d-flex flex-column align-center justify-center pa-0" cols="12">
                <p class="text-h5 lowr font-weight-bold mb-0 mt-2 descriptionCombination" v-if="description1 !== '' && description1 !== null">
                    {{description1}}
                </p>
                <p class="text-h5 lowr font-weight-bold mb-0 mt-2 descriptionCombination" v-if="description2 !== '' && description2 !== null">
                    {{description2}}
                </p>
                <div style=" display: flex; align-items: center; justify-content: center; ">
                    <a target="_blank"  :href="shareUrl" 
                        title="Share on WhatsApp"
                        style=" display: flex; align-items: center; justify-content: center; color: black;     margin-right: 10px;"
                        >
                        <svg style=" width: 20px; " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                            </svg>
                            <span class="share-btn-text">Partager</span>
                        </a>
                    <a target="_blank"  :href="shareUrl" class="share-btn share-btn-branded share-btn-WhatsApp"
                        title="Share on WhatsApp"
                        style=" background-color: #1fb256; border-color: #1da851; color: white; ">
                        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon-svg whatsapp-svg" fill="currentColor"><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"></path></svg>
                    </a>
                    <!-- <a class="share-btn share-btn-branded share-btn-pinterest"
                        href="https://www.pinterest.com/pin/create/button/?url=http%3A%2F%2Fcodepen.io%2Fsunnysingh%2Fpen%2FOPxbgq"
                        title="Share on Pinterest">
                        <svg style=" margin-right: 10px; " width="36" height="36" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> <path data-v-0ac6fb8c="" fill-rule="evenodd" clip-rule="evenodd" d="M21.1 29.8333H35.9V26.1667H21.1V29.8333ZM13.515 28C13.515 23.7833 16.845 20.4833 21.1 20.4833H26.65V17H21.1C14.995 17 10 21.95 10 28C10 34.05 14.995 39 21.1 39H26.65V35.5167H21.1C16.845 35.5167 13.515 32.2167 13.515 28ZM35.9 17H30.35V20.4833H35.9C40.155 20.4833 43.485 23.7833 43.485 28C43.485 32.2167 40.155 35.5167 35.9 35.5167H30.35V39H35.9C42.005 39 47 34.05 47 28C47 21.95 42.005 17 35.9 17Z" fill="white"></path></svg>
                        <span class="share-btn-text">Copier</span>
                    </a> -->
                </div>
                <p class="text-h5 lowr2 font-weight-bold mb-0">
                    Produit(s) conseillé(s) pour:
                </p>
                <p class="text-h5 lowr font-weight-bold mb-0 mt-2">
                    {{ productIds.title }}
                </p>
            </v-col>
        </v-row>
        <div class="d-flex pa-10 justify-center" v-if="loading">
            <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-row class="justify-center" v-if="suggestedProductList.length > 0">
            <v-col
                v-for="product in suggestedProductList"
                :key="product.id"
                class="d-flex flex-column py-0 px-10 mt-15"
                cols="12"
                md="4"
                sm="6"
            >
                <v-row>
                    <v-img :src="product.thumb_link" contain height="283" />
                </v-row>
                <v-row class="mt-5">
                    <h4 class="mb-0">{{ product.name }}</h4>
                </v-row>
                <v-row class="mb-1 d-flex flex-column mt-6">
                    <v-col v-if="product.brand" class="d-flex pa-0" cols="12" sm="5">
                        <p
                            class="mb-0 productName"
                        >
                            <span class="font-weight-bold">marque :</span> {{ product.brand }}
                        </p>
                    </v-col>
                    <v-col v-if="product.reference" class="d-flex pa-0" cols="12" sm="5">
                        <p class="mb-0 productName">
                            <span class="font-weight-bold">modèle :</span> {{ product.reference }}
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                        block
                        x-large
                        depressed
                        height="56px"
                        color="#E53939"
                        elevation="4"
                        class="text-capitalize text-h6 font-weight-bold"
                        @click="openLink(product.link)"
                    >
                        Voir le Produit</v-btn
                    >
                </v-row>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="block_cmd">
            <v-col cols="12" md="12" class="btncmd">
                <v-btn
                    block
                    x-large
                    depressed
                    height="56px"
                    color="#39a03e"
                    elevation="4"
                    class="comdd text-capitalize text-h6 font-weight-bold mt-2"
                    @click="showModalCommand"
                >
                Je Commande
                </v-btn>
            </v-col>
        </v-row>
        
        <v-row
            class="mx-12 mt-10 justify-center oldPriceColor noProductFound"
            v-if="suggestedProductList.length == 0 && loading == false && !noProductFound && noProductFoundFromDB"
        >
            No product found
        </v-row>
        <v-row
            class="mt-10 justify-center mcr oldPriceColor "
            v-if="suggestedProductList.length == 0 && loading == false && noProductFound">
         Merci de remplir tous les champs pour voir les produits 🙂
        </v-row>
    </v-container>
</template>

<script>
import { bus } from "../main";
import axios from "axios";
export default {
    name: "SuggestedProductList",
    props: ["productIds", "productData", "preFillData"],
    data: () => ({
        suggestedProductList: [],
        description1:"",
        description2:"",
        loading: false,
        noProductFound: false,
        noProductFoundFromDB:false
    }),
    watch: {
        productIds() {
            if (this.productIds) {
                this.loading = true;
                this.suggestedProductList = [];
                axios
                    .get("https://www.electrosolver.com/admin/api/get-products", {
                        params: this.productIds,
                    })
                    .then((response) => {
                        if (response.data.data) {
                            this.suggestedProductList = response.data.data.list;
                            this.description1 = response.data.data.combination_description1;
                            this.description2 = response.data.data.combination_description2;

                            console.log(JSON.stringify(response.data.data));
                            this.noProductFound = false
                        }else{
                            this.noProductFoundFromDB = true
                        }
                        this.loading = false;
                     // alert("scroll to show found products")
                        document.querySelector(".ssp-scroll").scrollIntoView({ behavior: 'smooth', block: 'start' })
                    });
            }
        },
    },
    created() {
        bus.$on('clearResultsValues', ()=>{
            // clear suggestedProductList evry time a new ship is selected
            // console.log("suggestedProductList ^^'");
            this.suggestedProductList = []
        })
        bus.$on('showNoProductFound', ()=>{
            // console.log('showNoProductFound');
            // clear suggestedProductList evry time a new ship is selected
            this.noProductFound = true
        })
    },
    computed: {
        // a computed getter
        shareUrl() {
            // `this` points to the component instance
            const urlToShare = `https://www.electrosolver.com/#/?category_id=${this.$route.query.category_id}&keyword_one_id=${this.$route.query.keyword_one_id}&keyword_two_id=${this.$route.query.keyword_two_id}`;
            return `https://api.whatsapp.com/send?text=${encodeURIComponent(urlToShare)}`;
        }
    },
    methods: {
        openLink(link) {
            window.open(link);
        },
        showModalCommand() {
            this.$emit('show-command-modal');

            // Scroll to the top of the page
            window.scrollTo(0, 0);
            
            // Prevent scrolling on the body
            document.documentElement.style.overflow = 'hidden';
        }
    },
};
</script>
<style scoped>
.mcr{
    font-size: 20px;
    text-align: center;
}
.lowr::first-letter {
        text-transform:capitalize;
}
.lowr2{
    font-size: 1.7rem !important;
    color: #e53939;
    }
.lowr{
    text-transform: lowercase;
    text-align: center;
}
.priceColor {
    color: #e53939;
}
.oldPriceColor {
    color: #757575;
}
.productName {
    font-size: 14px;
}
.noProductFound {
    font-size: 24px;
}
.v-btn >>> .v-btn__content {
    color: #fff;
    font-weight: bold;
    font-size: 25px;
}
.descriptionCombination
{
    font-size: 13pt !important;
    font-family: Roboto, sans-serif !important;
    color: #000000;
    /* background-color: transparent; */
    font-weight: 400 !important;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    /* white-space: pre-wrap; */
    width: 100%;
    background: white;
    border-radius: 5px;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.4;
    margin-top: 18px !important;
}
.btncmd{
    padding:25px !important;
}
.block_cmd
{
    width: 100%;
    position: fixed;
    width: 100vw;
    bottom: 5px;
    right: 0;
    left: 12px;
    /* margin: -12px; */
    background: white;
}
</style>
