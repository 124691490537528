var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedTag)?_c('v-container',{attrs:{"h-500":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pa-0 mt-10",attrs:{"cols":"12"}},[_c('div',{staticClass:"avtar mr-4"},[_c('span',{staticClass:"avtarFont"},[_vm._v("2")])]),_c('p',{staticClass:"text-h5 mb-0 mr-0 d-flex align-center"},[_vm._v("Sélectionnez le Type de Machine et le code d'erreur")])])],1),_c('v-row',{},[_c('v-col',{staticClass:"d-flex flex-column align-baseline pa-0 mt-10",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('div',{staticClass:"d-flex align-center pa-0"},[_c('p',{staticClass:"textField mb-0"},[_vm._v(" "+_vm._s(_vm.selectedTag && _vm.selectedTag.title ? _vm.selectedTag.title : "")+" ")])]),_c('div',{staticClass:"d-flex flex-column pa-0 mt-2 dropdownField br4"},[_c('v-autocomplete',{ref:"product1ref",staticClass:"dropdownBox mr-2",attrs:{"placeholder":"Effectuez une recherche","items":_vm.productList1,"outlined":"","item-text":"name","return-object":""},on:{"change":function($event){return _vm.fillSecondProduct()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"listItem"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"listImage"},[_c('img',{attrs:{"src":item.image,"height":"73","width":"66"}})])]}}],null,false,3849378803),model:{value:(_vm.product1),callback:function ($$v) {_vm.product1=$$v},expression:"product1"}}),(_vm.product1)?_c('v-row',{staticClass:"d-flex py-0 justify-center"},[_c('v-img',{attrs:{"src":_vm.product1.image,"contain":"","height":"150"}})],1):_vm._e(),(_vm.product1 && _vm.product1.reference)?_c('v-row',{staticClass:"d-flex mt-3 justify-center py-0 productDetailBox"},[_c('div',{staticClass:"d-flex pa-0 productName textitalic d-flex justift-content-center"},[_c('p',{staticClass:"mb-0 mt-3 productText"},[_vm._v(_vm._s(_vm.product1.reference))])])]):_vm._e()],1)]),(_vm.product1)?_c('v-col',{staticClass:"d-flex flex-column  align-baseline pa-0 mt-10 product2",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('div',{staticClass:"d-flex align-center pa-0 "},[_c('p',{staticClass:"textField mb-0 mr-8"},[_vm._v(" "+_vm._s(_vm.selectedTag && _vm.selectedTag.keyword ? _vm.selectedTag.keyword : "")+" ")])]),_c('div',{staticClass:"d-flex flex-column pa-0 mt-2 dropdownField "},[_c('v-autocomplete',{ref:"product2ref",staticClass:"dropdownBox ml-2",attrs:{"placeholder":"Effectuez une recherche","items":_vm.product1 ? _vm.productList2 : [],"outlined":"","item-text":"name","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"listItem"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"listImage"},[_c('img',{attrs:{"src":item.image,"height":"73","width":"66"}})])]}}],null,false,3849378803),model:{value:(_vm.product2),callback:function ($$v) {_vm.product2=$$v},expression:"product2"}}),(_vm.product2)?_c('v-row',{staticClass:"d-flex py-0 justify-center"},[_c('v-img',{attrs:{"src":_vm.product2.image,"contain":"","height":"180"}})],1):_vm._e(),(_vm.product2 && _vm.product2.brand)?_c('v-row',{staticClass:"d-flex mt-3 justify-center py-0 productDetailBox"},[_c('div',{staticClass:"d-flex pa-0 productName textitalic"},[_c('p',{staticClass:"mb-0 productText"},[_vm._v(_vm._s(_vm.product2.brand))])])]):_vm._e()],1)]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }