<template>
  <v-container>
    <v-row class="align-center searchBar">
      <v-col class="d-flex pa-0 mt-1 mb-2 " cols="12" md="4" sm="12" xs="12">
        <div class="avtar mr-4">
          <span class="avtarFont">1</span>
        </div>
        <p class="text-h5 mb-0 mr-4 d-flex align-center">Sélectionnez la marque de votre machine</p>
      </v-col>
      <v-col
        class="d-flex pa-0 mt-1 searchBox"
        cols="12"
        md="8"
        sm="12"
        xs="12"
      >
        <v-text-field
          placeholder="Effectuez une recherche"
          v-model="searchValue"
          @keyup="onSearch"
          outlined
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="sco">
      <v-col v-if="NoChips" class="d-flex pa-0 justify-center" cols="12">
        <h2 class="mb-3 mt-3">Pas de résultats trouvé. Merci de sélectionner parmi les besoins disponible.</h2>
      </v-col>
      <v-col class="d-flex pa-0 justify-center" cols="12">
        <div v-if="loading">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-chip-group column v-else v-model="selectedTag">
          <v-chip
            large
            v-for="tag in tags"
            :key="tag.id"
            outlined
            @change="onTagSelect(tag)"
          >
            <span>{{ tag.title }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col
        v-if="
          !loading &&
          !searchValue &&
          remainingTags.length > 0 &&
          tags.length != allTags.length
        "
        class="d-flex pa-0 justify-center customChip"
        cols="12"
      >
        <v-chip large @click="onOtherTag" class="other"> <span>Autres</span> </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from "../main";
import axios from "axios";
export default {
  name: "SelectionChips",
  props: ["preFillData"],
  data: () => ({
    loading: true,
    tags: [],
    remainingTags: [],
    allTags: [],
    searchValue: "",
    selectedTag: "",
    NoChips:false,
  }),
  mounted() {
    this.loading = true;
    axios
      .get("https://www.electrosolver.com/admin/api/get-categories")
      .then((response) => {
        this.allTags = response.data.data.list;
        this.getTags();
        this.loading = false;
      });
  },
  watch: {
    preFillData() {
      if (this.preFillData && this.allTags) {
        this.selectedTag = this.allTags.findIndex((tag, index) => {
          if (tag.id == this.preFillData.category_id) {
            this.$emit("getSelectedTag", tag);
            return index;
          }
        });
        this.tags = this.allTags;
      }
    },
  },
  methods: {
    onTagSelect(value) {
      this.$emit("getSelectedTag", value);
      bus.$emit("clearResultsValues");
    },
    onOtherTag() {
      this.getTags();
    },
    getTags() {
      this.tags = this.allTags.slice(0, this.tags.length + 8);
      this.remainingTags = this.allTags.slice(this.tags.length);
    },
    onSearch() {
      this.tags = this.allTags.filter((tag) => {
        if (
          tag.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) != -1
        ) {
          return tag;
        }
      });
        if(!this.tags.length){
          this.NoChips = true
          this.tags = this.allTags
          bus.$emit("clearResultsValues");
          this.$emit("getSelectedTag", {});

        }else{
          this.NoChips = false
        }
    },
  },
};
</script>
<style scoped>
.other{
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sco{
  margin-top: 20px!important;
}
@media screen and (min-width: 1087px) {
  .searchBox {
    max-width: 350px;
    height: 65px;
  }
}
.searchBox {
  width: 100%;
  height: 65px;
  
}
.v-text-field--outlined >>> fieldset {
  border: 1px solid #000000;
  border-radius: 4px;
}
.v-text-field >>> input {
  font-size: 25px;
}
.v-text-field >>> input::placeholder {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.25) !important;
  align-self: center;
  position: absolute;
  top: 3px;
}
.customChip >>> .v-chip {
  border: 1px solid #1a97de !important;
  background-color: #fff !important;
  border-radius: 35px;
  padding: 0 25px;
  color: #1a97de;
  font-size: 25px;
  cursor: pointer;
  max-width: 150px;
}
.v-chip-group--column >>> .v-slide-group__content {
  justify-content: center;
}
.v-chip-group--column >>> .v-chip--outlined {
  border: 1px solid #000000;
}
.v-chip-group--column >>> .v-chip {
  border-radius: 35px;
  /* margin: 20px 15px 8px 0; */
  padding: 0 25px;
  font-size: 25px;
}
.v-chip-group--column >>> .v-chip--active {
  font-weight: bold;
  border: 3px solid #000000;
  background-color: #fff !important;
}

@media (max-width: 480px) {
  .v-chip-group--column >>> .v-chip {
    font-size: 23px;
    white-space: normal;
  }
}
</style>
