<template>
    <div v-if="showModal" class="modal-backdrop" id="Modal_AddCommands" @click="closeModal">
      <div class="modal" @click.stop>
        <button @click="closeModal" class="close">x</button>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <input v-model="name" placeholder="Nom" class="form-control" required>
          </div>
          <div class="form-group">
            <input v-model="ville" placeholder="Ville" class="form-control" required>
          </div>
          <div class="form-group">
            <input v-model="numberphone" type="number" placeholder="Numéro de téléphone" class="form-control" required>
          </div>
          <div class="form-group">
            <button type="submit" class="btn form-control validCmd">Valider la Commande</button>
          </div>
        </form>
        <!-- Vuetify Snackbar -->
        <v-snackbar v-model="snackbar.show" :timeout="3000" :color="snackbar.color" top>
            <v-icon left v-if="snackbar.icon">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
data() {
    return {
    showModal: false,
    name: '',
    ville: '',
    numberphone: '',
    snackbar: {
        show: false,
        text: '',
        color: ''
    }
    }
},
methods: {
    async submitForm() {
        if (!this.isValidPhoneNumber()) {
            this.showSnackbar('Le numéro de téléphone doit comporter 10 caractères !', 'error');
            return;
        }

        try {
            let response = await axios.post('https://www.electrosolver.com/admin/api/add-command', {
            name: this.name,
            ville: this.ville,
            phone_number: this.numberphone,
            link: window.location.href
            });

            if (response.data.success) {
                this.showSnackbar('Commande ajoutée avec succès! Nous vous contacterons bientôt.', 'success');
                // Clear input values
                this.name = '';
                this.ville = '';
                this.numberphone = '';
                // Delay hiding the modal by 3 seconds (3000 milliseconds)
                setTimeout(() => {
                    this.showModal = false;
                    document.documentElement.style.overflow = 'auto';
                }, 3000);
            } else {
                this.showSnackbar("Erreur lors de l'ajout de la commande.", 'error');
                document.documentElement.style.overflow = 'auto';
            }
        } catch (error) {
            console.error("Error:", error.response);
        }
    },
    closeModal() {
    this.showModal = false;
    document.documentElement.style.overflow = 'auto';
    },
    isValidPhoneNumber() {
    return this.numberphone.length === 10;
    },
    showSnackbar(text, type) {
        this.snackbar.text = text;
        if(type == 'success') {
            this.snackbar.color = 'green';
            this.snackbar.icon = 'mdi-check-circle'; // This is a 'success' icon
        } else if(type == 'error') {
            this.snackbar.color = 'red';
            this.snackbar.icon = 'mdi-alert-circle'; // This is an 'error' icon
        }
        this.snackbar.show = true;
    }
}
}
</script>

<style>
#Modal_AddCommands {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

#Modal_AddCommands::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: .7;
    z-index: -1;
}

.modal {
    /* Your modal styles here. For example: */
    position: relative;
    background-color: #fff;
    padding: 1rem;
    width: 80%;
    margin: 5% auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* semi-transparent black */
    z-index: 1000; /* ensure the backdrop is above everything else */
    display: flex;
    align-items: center;
    justify-content: center;
}
#Modal_AddCommands .close {
    position: absolute;   /* This will position the button relative to its closest positioned ancestor */
    top: 10px;            /* Adjust as needed for your design */
    right: 10px;          /* Adjust as needed for your design */
    background-color: transparent;
    border: none;
    font-size: 20px;      /* Makes the 'x' larger; adjust as needed */
    cursor: pointer;
    padding: 0;
    color: #333;          /* Adjust color as needed */
    line-height: 1;
    outline: none;
}
.modal {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 80%; /* you can adjust this */
    max-width: 500px; /* you can adjust this */
}
/* Mimic Bootstrap's .form-group */
.form-group {
    margin-bottom: 1rem;
}

/* Mimic Bootstrap's .form-control */
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
/* Mimic Bootstrap's .btn */
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

/* Mimic Bootstrap's .btn-primary */
.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0056b3;
    border-color: #004999;
}

.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}
.v-snackbar__content {
    font-weight: bold;
    color: #f5f5f5;
}
.titleModal
{
    margin-bottom:20px;
    text-align: center;
}
.validCmd
{
    background:#39a03e;
    color: white;
}
.validCmd:hover
{
    background:#111;
    color:white;
}
/* For Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For IE */
input[type="number"]::-ms-clear {
  display: none;
}

</style>

<!-- Your styles can go here -->

