<template>
  <v-container v-if="selectedTag" h-500>
    <v-row>
      <v-col class="d-flex align-center pa-0 mt-10" cols="12">
        <div class="avtar mr-4">
          <span class="avtarFont">2</span>
        </div>
        <p class="text-h5 mb-0 mr-0 d-flex align-center">Sélectionnez le Type de Machine et le code d'erreur</p>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col class="d-flex flex-column align-baseline pa-0 mt-10" cols="12" md="6" sm="12">
        <div class="d-flex align-center pa-0">
          <p class="textField mb-0">
            {{ selectedTag && selectedTag.title ? selectedTag.title : "" }}
          </p>
        </div>
        <div class="d-flex flex-column pa-0 mt-2 dropdownField br4">
            <v-autocomplete
              v-model="product1"
              @change="fillSecondProduct()"
              class="dropdownBox mr-2"
              placeholder="Effectuez une recherche"
              :items="productList1"
              outlined
              item-text="name"
              ref="product1ref"
              return-object
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <span class="listItem">
                  {{ item.name }}
                </span>
                <span class="listImage">
                  <img :src="item.image" height="73" width="66" />
                </span>
              </template>
            </v-autocomplete>
          <v-row v-if="product1" class="d-flex py-0 justify-center">
            <v-img :src="product1.image" contain height="150" />
          </v-row>
          <!-- <v-row v-if="product1 && product1.brand" class="d-flex mt-3 justify-center py-0 productDetailBox">
            <div class="d-flex pa-0 productName textitalic">
              <p class="mb-0 productText sboldText">Marque:&nbsp;</p>
              <p class="mb-0 productText">{{ product1.brand }}</p>
            </div>
          </v-row> -->

          <v-row v-if="product1 && product1.reference" class="d-flex mt-3 justify-center py-0 productDetailBox">
            <div class="d-flex pa-0 productName textitalic d-flex justift-content-center">
              <!-- <p class="mb-0 productText sboldText">Modéle:&nbsp;</p> -->
              <p class="mb-0 mt-3 productText">{{ product1.reference }}</p>
            </div>
          </v-row>
        </div>
      </v-col>

      <v-col v-if="product1" class="d-flex flex-column  align-baseline pa-0 mt-10 product2" cols="12" md="6" sm="12">
        <div class="d-flex align-center pa-0 ">
          <p class="textField mb-0 mr-8">
            {{ selectedTag && selectedTag.keyword ? selectedTag.keyword : "" }}
          </p>
        </div>
        <div class="d-flex flex-column pa-0 mt-2 dropdownField ">
            <v-autocomplete
              v-model="product2"
              class="dropdownBox ml-2"
              placeholder="Effectuez une recherche"
              :items="product1 ? productList2 : []"
              outlined
              item-text="name"
              ref="product2ref"
              return-object
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <span class="listItem">
                  {{ item.name }}
                </span>
                <span class="listImage">
                  <img :src="item.image" height="73" width="66" />
                </span>
              </template>
            </v-autocomplete>
          <v-row v-if="product2" class="d-flex py-0 justify-center">
            <v-img :src="product2.image" contain height="180" />
          </v-row>
          <v-row v-if="product2 && product2.brand" class="d-flex mt-3 justify-center py-0 productDetailBox">
            <div class="d-flex pa-0 productName textitalic">
              <!-- <p class="mb-0 productText sboldText">Marque:&nbsp;</p> -->
              <p class="mb-0 productText">{{ product2.brand }}</p>
            </div>
          </v-row>

          <!-- <v-row v-if="product2 && product2.reference" class="d-flex mt-3 justify-center py-0 productDetailBox ml-3">
            <div class="d-flex pa-0 productName textitalic">
              <p class="mb-0 productText sboldText">Modéle:&nbsp;</p>
              <p class="mb-0 productText">{{ product2.reference }}</p>
            </div>
          </v-row> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from "../main";

import axios from "axios";
export default {
  name: "ProductList",
  props: ["selectedTag", "preFillData"],
  data: () => ({
    productList1: [],
    productList2: [],
    product1: "",
    product2: "",
  }),
  watch: {
    product1: function () {
      this.product2 = "";
      this.productList2 = [];
      bus.$emit("showNoProductFound");

      if(this.product1){
        this.$refs.product1ref.blur()
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          setTimeout(function(){
          //  alert("scroll to the second select box")
            document.querySelector(".product2").scrollIntoView({ behavior: 'smooth', block: 'start' })
          }, 500);
        }
      }
    },
    product2: function () {
     if (this.product1) {
      this.getSuggestedProduct()
      this.$refs.product2ref.blur()
     }
    },
    selectedTag() {
      if (this.selectedTag) {
        this.product1 = "";
        this.product2 = "";
        this.productList1 = [];
        this.productList2 = [];
        this.getProductList();
      }
    },
    preFillData() {
      if (this.preFillData) {
        this.getProductList();
      }
    },
  },
  methods: {
    getProductList() {
      if(this.selectedTag.id){
      this.$router.replace({ query: { category_id: this.selectedTag.id} })
        axios
          .get(
            "https://www.electrosolver.com/admin/api/get-keywords-one",
            {
              params: {
                category_id: this.selectedTag.id,
              },
            }
          )
          .then((response) => {
            const res = response.data.data.list;
            if (res) {
              res.map((product) => {
                product.name = product.title;
                product.image = product.thumb_link;
                return product;
              });
            //  alert("scroll to 'Sélectionnez deux produits'")
              document.querySelector(".content-class-scroll").scrollIntoView({ behavior: 'smooth', block: 'start' })

              this.productList1 = res;
              if (this.preFillData) {
                const product1Data = this.productList1.filter(
                  (product) => product.id == this.preFillData.keyword_one_id
                );
                this.product1 = product1Data[0];
                this.getSuggestedProduct();
              }
            }
          });
      }
    },
    fillSecondProduct() {
      this.$router.replace({ query: { category_id: this.selectedTag.id,keyword_one_id: this.product1.id } })
        axios
        .get(
          "https://www.electrosolver.com/admin/api/get-linked-keywords-two",
          {
            params: {
              category_id: this.selectedTag.id,
              keyword_one_id: this.product1.id
            },
          }
        )
        .then((response) => {
          const res = response.data.data.list;
          if (res) {
            res.map((product) => {
              product.name = product.title;
              product.image = product.thumb_link;
              product.title = "Reference";
              return product;
            });
            this.productList2 = res;
            if (this.preFillData) {
              const product2Data = this.productList2.filter(
                (product) => product.id == this.preFillData.keyword_two_id
              );
              this.product2 = product2Data[0];
              this.getSuggestedProduct();
            }
          }
        });
    },
    getSuggestedProduct() {
      this.$router.replace({ query: { category_id: this.selectedTag.id,keyword_one_id: this.product1.id,keyword_two_id: this.product2.id} })

      const productData = {
        product1: this.product1,
        product2: this.product2,
      };
      const data = {
        search_term: this.selectedTag.title,
        title:
          this.selectedTag.title +
          " " +
          this.product1.title +
          " " +
          this.selectedTag.keyword +
          " " +
          this.product2.title,
        category_id: this.selectedTag.id,
        keyword_one_id: this.product1.id,
        keyword_two_id: this.product2.id,
      };
      this.$emit("getProductIds", data, productData);
    },
  },
  created() {
      if(this.$route.query.category_id && this.$route.query.keyword_one_id && this.$route.query.keyword_two_id){
        const data = {
          search_term: 'search',
          category_id: this.$route.query.category_id,
          keyword_one_id: this.$route.query.keyword_one_id,
          keyword_two_id: this.$route.query.keyword_two_id,
        };
        this.$emit("getProductIds", data);
      }
    }
};
</script>
<style>
.sboldText{
  font-weight: 500;
}
.textitalic p{
  font-style: italic;
}
.v-application .text-h5{
  font-weight: 500;
}
.ht{
  font-weight: 500;
}
.hred{
  color: #e53939;
  font-weight: 700!important;
}
body{overflow-y:scroll;
margin-left: 10px;
}
 ::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 12px;
}
::-webkit-scrollbar:horizontal {
    height: 12px;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
.v-autocomplete__content{
  max-height: 260px!important;
}
</style>
<style scoped>

.h-500{
  min-height: 500px;
}
.productSelection {
  max-width: 100%;
}
@media screen and (max-width: 1087px) {
  .dropdownBox{
    margin-left: 0px!important;
    margin-right: 0px!important;
  }
}
@media screen and (min-width: 1087px) {
  .dropdownField {
    width: 54%;
  }  
}
.dropdownField {
  width: 100%;
}
.productDetailBox {
  width: 100%;
}
.productName {
  width: 50%;
}
.br4{
  border-radius: 4px;
}
.v-text-field--outlined >>> fieldset {
  border: 1px solid #000000;
  border-radius: 4px;
  width: 100%;
}
.v-text-field >>> .v-select__selection--comma {
  font-size: 25px;
}
.v-text-field >>> input::placeholder {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.25);
  align-self: center;
  position: absolute;
  top: 3px;
}
.productText {
  font-size: 25px;
}
.boldText {
  font-weight: bold;
}
.v-btn >>> .v-btn__content {
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}
.listItem {
  width: 75%;
  margin-left: 10px;
}
.listImage {
  margin: 10px;
}
.productName
{
   justify-content: center;
}
.dropdownField
{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.dropdownBox
{
    width: 100%;
}
.productDetailBox
{
  margin-top: 0px !important;
}
</style>
