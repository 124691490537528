import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import AllSolutions from '../components/AllSolutions'
// import Login from '../components/Login'
// import Dashboard from '../components/Dashboard'

Vue.use(VueRouter)

const routes = [
    { path: '/allSolutions', name: 'allSolutions', component: AllSolutions, props: true },
    { path: '/:search_term?/:category_id?/:keyword_one_id?/:keyword_two_id?', name: 'home', component: Home, props: true },
    // { path: '/login', name: 'login', component: Login, props: true },
    // { path: '/dashboard', name: 'dashboard', component: Dashboard, props: true }
]

export default new VueRouter({
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})