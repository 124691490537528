<template>
  <v-container>
    <v-row>
      <v-col class="d-flex align-center justify-center mt-10 mb-5" cols="12">
        <v-btn
          x-large
          depressed
          width="350px"
          height="56px"
          color="#E53939"
          elevation="4"
          class="text-capitalize text-h6 font-weight-bold midbtn "
          @click="backToHome"
          style="height: 56px; width: 303px; background-color: transparent; border: 2px solid rgb(229, 57, 57);"
        >
        <span class="v-btn__content" style="color: rgb(229, 57, 57);">Nos Solutions</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="my-10">
      <v-col class="d-flex align-center justify-center pa-0" cols="12">
        <p class="subHeader font-weight-bold mb-0">Toutes les Solutions</p>
      </v-col>
    </v-row>
    <div
      class="d-flex pa-10 justify-center"
      v-if="loading && allLinks.length == 0"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <v-row class="my-1" v-if="allLinks.length > 0">
      <v-col
        v-for="data in allLinks"
        :key="data.link"
        class="d-flex flex-column align-self-baseline py-0 mb-6"
        cols="12"
        md="4"
        sm="12"
      >
        <v-row class="justify-start">
          <a target="_blank" :href="data.link">{{
            data.title
          }}</a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { bus } from "../main";
export default {
  name: "AllSolutions",

  data: () => ({
    allLinks: [],
    loading: false,
  }),
  mounted() {
    this.loading = true;
    window.scrollTo(0, 0)
    axios
      .get("https://www.electrosolver.com/admin/api/get-searched-terms")
      .then((response) => {
        this.allLinks = response.data.data.list;
        this.loading = false;
      });
  },
  methods: {
    backToHome() {
      this.$router.push("/");
    },
    goToLink(apiLink) {
      axios.get(apiLink).then((response) => {
        this.preFillData = response.data.data;
        bus.$emit("setPreFillData", this.preFillData);
        this.backToHome();
      });
    },
  },
};
</script>
<style scoped>
.midbtn::before{
  background-color: #fff;
}
.link {
  font-size: 18px;
  color: #1a97de;
  margin: 0 5px;
}
.v-btn >>> .v-btn__content {
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}
</style>
