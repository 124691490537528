<template>
  <v-app>
    <v-main >
       <v-app-bar
      color="white"
      dark
      class="fr-reverse"
    >
    <a href="/" class="logolink">
      <img 
      class="logoimage"
      :src="require('./assets/logo.jpeg')" alt="logo">
    </a>
      <!-- <v-app-bar-nav-icon @click="drawer = true" class="nav_icon_bar"  color="black"></v-app-bar-nav-icon> -->

    </v-app-bar>

    <!-- <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
      
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>

<style >
.logolink{
  height: 50px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.logoimage{
  object-fit: contain;
  height: 100%;
}
.nav_icon_bar{
  position: absolute!important;;
  left: 10px;
}
.v-toolbar{
    box-shadow: none!important;
}
.heading-sizes {
    margin-top: 30px;
}
.divider {
    height: 5px;
}
@font-face {
    font-family: "share-buttons";
    src: url("https://cdn.rawgit.com/sunnysingh/share-buttons/v1.0.0/build/fonts/share-buttons.eot?gpra60");
    src: url("https://cdn.rawgit.com/sunnysingh/share-buttons/v1.0.0/build/fonts/share-buttons.eot?#iefixgpra60") format("embedded-opentype"), url("https://cdn.rawgit.com/sunnysingh/share-buttons/v1.0.0/build/fonts/share-buttons.woff?gpra60") format("woff"), url("https://cdn.rawgit.com/sunnysingh/share-buttons/v1.0.0/build/fonts/share-buttons.ttf?gpra60") format("truetype"), url("https://cdn.rawgit.com/sunnysingh/share-buttons/v1.0.0/build/fonts/share-buttons.svg?gpra60#share-buttons") format("svg");
    font-weight: normal;
    font-style: normal;
}
.share-btn-icon {
    font-family: "share-buttons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.share-btn-email .share-btn-icon:before {
    content: "\e945";
}
.share-btn-more .share-btn-icon:before {
    content: "\ea82";
}
.share-btn-googleplus .share-btn-icon:before {
    content: "\ea88";
}
.share-btn-facebook .share-btn-icon:before {
    content: "\ea8c";
    font-size: 18px;
}
.share-btn-twitter .share-btn-icon:before {
    content: "\ea91";
}
.share-btn-pinterest .share-btn-icon:before {
    content: "\ead0";
}
.share-btn,
.share-btn * {
    box-sizing: border-box;
}
.share-btn,
.share-btn *,
.share-btn *:before,
.share-btn *:after {
    box-sizing: border-box;
}
.share-btn {
    position: relative;
    margin: 0;
    padding: 2px 8px;
    line-height: 1.53;
    letter-spacing: 0.04em;
    vertical-align: top;
    font-size: 12px;
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, sans-serif;
    color: #111;
    background: #e0e0e0;
    border: 1px solid #c7c7c7;
    text-decoration: none;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 4px!important;
}
.share-btn.share-btn-branded.share-btn-WhatsApp:active {
    box-shadow: 0 0 0 0.2rem #77e7a1;
}
.share-btn.share-btn-branded.share-btn-WhatsApp:hover,
.share-btn.share-btn-branded.share-btn-WhatsApp:focus {
    box-shadow: 0 0 0 0.2rem #77e7a1;
}
.v-application--wrap
{
    background: url(https://electrodelivery.ma/img/electro-motif-blck-18.png);
}
.theme--dark.v-toolbar.v-sheet
{
    background: transparent !important;
}
.v-text-field--outlined>.v-input__control>.v-input__slot
{
    background:white;
}
.v-chip.v-chip--outlined.v-chip.v-chip
{
    background:white !important;
}
header
{
  margin-top: 20px;
}
</style>
