<template>
  <v-container class="mb-5">
    <v-row class="mt-16 mb-4 d-none">
      <v-col
        class="d-flex align-center justify-center pa-0 my-10 topMargin"
        cols="12"
      >
        <p class="subHeader font-weight-bold mb-0">Les solutions les plus recherchées</p>
      </v-col>
    </v-row>
    <v-row class="my-1 d-none">
      <v-col
        v-for="data in mostSearchedLinks"
        :key="data.link"
        class="d-flex flex-column align-self-baseline py-0 mb-6"
        cols="12"
        md="4"
        sm="12"
      >
        <v-row class="justify-start">
          <a target="_blank" :href="data.link">{{data.title}}</a>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col id="selectTous" class="d-flex align-center justify-center mt-5 mb-12" cols="12">
        <a  href="https://www.electrosolver.com/admin/api/allSolutions" style=" text-decoration: none; ">
        <v-btn
          x-large
          depressed
          width="303px"
          height="56px"
          color="#E53939"
          elevation="4"
          class="text-capitalize text-h6 font-weight-bold midbtn"
          
          style="height: 56px;width: 303px;background-color: white;border-color: rgb(229, 57, 57);border: 2px solid rgb(229, 57, 57);"
        >
          <span class="v-btn__content" style=" color: #E53939; "> Toutes les solutions </span>
        </v-btn>
      </a>
      </v-col>
    </v-row>
    <v-row>
          <v-col
              class="d-flex flex-column align-self-baseline py-0 mb-6 itemsHelps"
              cols="12"
              md="4"
              sm="12"
            >
            <div class="Information-item">
              <div class="Information-item-icon">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                      width="70px" height="70px" viewBox="0 0 100.000000 100.000000"
                      preserveAspectRatio="xMidYMid meet">
                      <metadata>
                      Created by potrace 1.16, written by Peter Selinger 2001-2019
                      </metadata>
                      <g transform="translate(0.000000,100.000000) scale(0.019531,-0.019531)"
                      fill="rgb(229, 57, 57)" stroke="none">
                      <path d="M2775 4645 c-516 -83 -957 -403 -1196 -868 -69 -135 -128 -307 -155
                      -452 -26 -137 -26 -460 -1 -590 28 -143 93 -330 156 -450 l58 -110 -552 -555
                      c-640 -643 -610 -605 -610 -780 0 -94 3 -111 28 -161 33 -67 118 -151 184
                      -181 40 -18 66 -22 153 -23 175 0 136 -31 780 610 l555 552 110 -58 c124 -65
                      269 -117 425 -151 91 -19 134 -22 320 -22 186 0 229 3 320 22 659 143 1135
                      617 1282 1278 19 86 22 129 22 324 0 196 -3 238 -22 326 -142 650 -625 1135
                      -1270 1275 -129 28 -453 35 -587 14z m385 -466 c269 -33 495 -143 685 -334
                      168 -167 269 -354 321 -590 21 -97 24 -330 5 -425 -94 -480 -444 -835 -923
                      -936 -112 -24 -324 -24 -436 0 -235 50 -428 153 -593 316 -101 101 -161 183
                      -222 305 -87 173 -120 317 -120 515 0 145 10 218 50 345 164 526 694 871 1233
                      804z"/>
                      <path d="M3095 3901 c-22 -10 -52 -31 -67 -47 -96 -103 -42 -279 95 -314 201
                      -51 356 -199 412 -392 22 -77 52 -119 105 -145 80 -41 166 -26 227 38 69 73
                      67 182 -6 333 -164 338 -570 618 -766 527z"/>
                      </g>
                  </svg>
              </div>
              <div class="Information-item-text"><h3>Découvrez la Meilleure Solution </h3></div>
            </div>
          </v-col>
          <v-col
            class="d-flex flex-column align-self-baseline py-0 mb-6 itemsHelps"
            cols="12"
            md="4"
            sm="12"
          >
            <div class="Information-item">
              <div class="Information-item-icon">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="70px" height="70px" viewBox="0 0 100.000000 99.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                  </metadata>
                  <g transform="translate(0.000000,99.000000) scale(0.019531,-0.019336)"
                  fill="rgb(229, 57, 57)" stroke="none">
                  <path d="M3817 4639 c-14 -8 -339 -640 -358 -697 -2 -7 -150 -33 -381 -66
                  -208 -31 -387 -60 -397 -66 -32 -17 -51 -52 -51 -95 -1 -39 4 -44 281 -316
                  l281 -275 -61 -355 c-63 -366 -68 -416 -51 -450 16 -28 68 -52 104 -46 17 3
                  177 83 356 177 179 95 330 173 335 173 6 0 162 -80 348 -178 303 -159 342
                  -177 374 -172 43 7 80 39 88 76 3 14 -24 195 -59 401 l-65 375 57 54 c31 30
                  157 154 280 274 222 219 222 220 222 263 0 34 -6 51 -27 74 l-28 30 -380 55
                  c-209 30 -381 55 -382 55 -1 0 -78 154 -171 343 -109 222 -177 350 -193 360
                  -26 19 -94 22 -122 6z m363 -896 c14 -6 157 -31 319 -54 l294 -43 -216 -211
                  c-119 -115 -221 -223 -227 -238 -8 -22 1 -91 40 -319 28 -160 49 -294 48 -298
                  -2 -4 -120 55 -263 131 -188 100 -270 138 -295 138 -26 1 -102 -35 -300 -138
                  -146 -77 -266 -139 -267 -138 0 1 21 130 48 287 27 157 49 299 49 315 0 26
                  -29 59 -223 251 l-224 221 296 42 c206 29 304 47 321 59 17 12 70 108 160 288
                  l135 271 140 -276 c106 -210 146 -279 165 -288z"/>
                  <path d="M64 3070 c-12 -4 -31 -21 -43 -36 l-21 -27 0 -736 c1 -783 -2 -749
                  50 -784 22 -15 63 -17 415 -17 320 0 401 -3 442 -15 39 -12 201 -121 660 -445
                  334 -236 640 -445 678 -464 149 -73 96 -70 1023 -73 724 -3 841 -2 893 12 117
                  30 210 106 263 215 29 61 31 72 31 175 0 104 -2 114 -32 176 -56 114 -164 195
                  -293 219 -30 6 -245 10 -512 10 -434 0 -460 1 -453 18 62 144 57 291 -12 412
                  -30 53 -1051 1083 -1161 1171 -89 72 -227 140 -342 171 -84 22 -96 22 -825 25
                  -409 1 -749 -2 -761 -7z m596 -795 l0 -595 -230 0 -230 0 0 595 0 595 230 0
                  230 0 0 -595z m898 579 c92 -19 177 -53 252 -102 32 -21 282 -262 598 -576
                  580 -578 592 -592 592 -685 -1 -88 -63 -174 -146 -201 -47 -16 -130 -8 -180
                  18 -22 11 -156 125 -299 252 -143 128 -271 237 -285 242 -38 14 -86 1 -114
                  -33 -30 -36 -33 -72 -8 -115 27 -49 524 -487 594 -524 113 -61 99 -60 848 -60
                  l686 0 44 -22 c114 -59 147 -203 67 -295 -67 -77 -16 -73 -906 -73 -872 0
                  -855 -1 -971 61 -30 16 -316 214 -635 440 -319 226 -605 423 -636 439 -51 26
                  -80 35 -171 55 l-28 5 0 595 0 595 310 0 c245 0 327 -3 388 -16z"/>
                  <path d="M1720 2069 c-62 -24 -81 -113 -36 -166 21 -24 32 -28 76 -28 44 0 55
                  4 76 28 46 54 26 144 -38 166 -37 13 -45 13 -78 0z"/>
                  </g>
                  </svg>
              </div>
              <div class="Information-item-text"><h3>Recommandations Personnalisées</h3></div>
            </div>
          </v-col>
          <v-col
              class="d-flex flex-column align-self-baseline py-0 mb-6 itemsHelps"
              cols="12"
              md="4"
              sm="12"
            >
            <div class="Information-item">
              <div class="Information-item-icon">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="70px" height="70px" viewBox="0 0 100.000000 100.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <metadata>
                  Created by potrace 1.16, written by Peter Selinger 2001-2019
                  </metadata>
                  <g transform="translate(0.000000,100.000000) scale(0.050000,-0.050000)"
                  fill="rgb(229, 57, 57)" stroke="none">
                  <path d="M575 1975 c-260 -70 -448 -243 -531 -490 -21 -62 -28 -106 -32 -195
                  -6 -150 13 -244 72 -365 97 -195 245 -320 456 -387 63 -19 96 -23 210 -23 114
                  0 147 4 210 23 92 30 160 63 226 112 l51 38 73 -73 73 -73 1 -76 1 -76 187
                  -187 187 -187 63 -4 c73 -4 115 16 148 70 24 39 27 117 6 156 -18 33 -342 355
                  -374 372 -14 6 -50 10 -83 8 l-58 -3 -75 74 -74 74 39 52 c52 70 103 183 124
                  279 21 96 16 280 -10 366 -78 252 -280 447 -529 510 -94 24 -282 26 -361 5z
                  m320 -100 c341 -86 554 -424 479 -758 -77 -341 -408 -560 -744 -493 -424 85
                  -645 545 -444 926 82 156 243 284 411 324 78 19 225 19 298 1z m827 -1522
                  c102 -101 168 -175 168 -187 0 -28 -29 -56 -59 -56 -19 0 -62 37 -188 163
                  -170 170 -182 189 -146 229 33 36 53 23 225 -149z"/>
                  <path d="M500 1639 c-123 -61 -228 -115 -232 -122 -4 -7 -8 -127 -8 -268 0
                  -215 2 -259 15 -269 8 -7 117 -61 242 -122 l227 -109 40 16 c23 9 133 62 246
                  118 l205 102 2 260 c2 143 0 265 -5 272 -11 17 -464 233 -487 232 -11 0 -121
                  -50 -245 -110z m300 -24 l45 -23 -158 -76 -159 -76 -46 24 -47 24 155 76 c85
                  42 157 76 160 76 3 -1 25 -12 50 -25z m222 -106 c49 -25 59 -17 -130 -105
                  l-143 -68 -47 24 -47 23 150 73 c83 41 156 74 163 74 7 0 31 -9 54 -21z m-486
                  -188 l164 -80 0 -175 c0 -97 -2 -176 -4 -176 -2 0 -79 36 -170 79 l-166 80 0
                  175 c0 97 3 176 6 176 3 0 80 -36 170 -79z m604 -96 l0 -175 -167 -80 c-91
                  -44 -168 -80 -170 -80 -1 0 -3 80 -3 178 l1 177 162 77 c89 43 165 78 170 78
                  4 0 7 -79 7 -175z"/>
                  <path d="M426 1277 c-12 -9 -17 -23 -14 -42 3 -27 13 -35 90 -74 85 -43 88
                  -43 113 -27 28 18 33 57 10 76 -20 16 -154 80 -169 80 -7 0 -20 -6 -30 -13z"/>
                  </g>
                  </svg> 
              </div>
              <div class="Information-item-text"><h3>Des Produits Adaptés à Vos Besoins</h3></div>
            </div>
          </v-col>
    </v-row>
    <v-row class="mb-15 mt-0">
      <v-col class="d-flex align-center justify-center pt-2" cols="12" style="background:hsla(0,0%,100%,.7000000000000001);">
        <p style="color: #7a7a7a;font-weight: 400;margin:0px;"> © 2023 - Solution de Electrodelivery.ma</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "MostSearchedList",

  data: () => ({
    mostSearchedLinks: [],
    preFillData: null,
  }),
  mounted() {
    axios
      .get(
        "https://www.electrosolver.com/admin/api/get-most-searched-terms"
      )
      .then((response) => {
        this.mostSearchedLinks = response.data.data;
      });
  },
  methods: {
    GoToAllLinks() {
      this.$router.push("allSolutions");
    },
    goToLink(apiLink) {
      axios.get(apiLink).then((response) => {
        this.preFillData = response.data.data;
        this.$emit("setPreFillData", this.preFillData);
      });
    },
  },
};
</script>
<style scoped>
.midbtn::before{
  background-color: #fff;
}
.topMargin {
  margin-top: 120px !important;
}
.link {
  font-size: 18px;
  color: #1a97de;
  margin: 0 5px;
}
.v-btn >>> .v-btn__content {
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}

.Information-item
{
  padding: 15px;
}

.Information-item-icon
{
    display: flex;
    justify-content: center;
}
.Information-item-text h3
{
  text-align: center;
}
.itemsHelps
{
  background: rgb(255 255 255 / 70%);
  padding-top: 0px !important;
  border-radius: 5px;
}
#app > div > main > div > header
{
  margin-top: 19px;
}
#selectTous
{
  display:none !important;
}
</style>
