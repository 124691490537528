<template>
  
  <v-container>
    <v-row class="text-center my-2">
      <v-col>
        <p class="subHeader ht">
          Découvrez la <span class="hred">solution technique</span> qui résout votre <span class="hred">Problème</span>
        </p>
      </v-col>
    </v-row>
    <div>
      <SelectionChips
        :preFillData="preFillData"
        @getSelectedTag="onGetSelectedTag"
      />
      <div class="content-class-scroll">
          <ProductList
            :preFillData="preFillData"
            :selectedTag="selectedTag"
            @getProductIds="onGetProductIds"
          />
      </div>
      <div class="ssp-scroll mt-8">
          <SuggestedProductList
            :preFillData="preFillData"
            :productIds="productIds"
            :productData="productData"
            @show-command-modal="handleModal"
          />
      </div>
      <MostSearchedList @setPreFillData="onSetPreFillData" />
    </div>
    <button @click="scrollToTop()" id="scroll" title="Scroll to Top">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2944 8.28236C13.4934 8.47508 13.7509 8.56958 14.0085 8.56585C14.2661 8.56212 14.5212 8.46141 14.7153 8.26247L14.7299 8.24755C14.913 8.05109 15.0022 7.79993 14.9985 7.55002C14.9948 7.3001 14.8984 7.05143 14.7092 6.85995C12.5534 4.66416 10.3623 2.49199 8.19063 0.30988C8.18453 0.301177 8.17842 0.293717 8.1711 0.286257C7.97212 0.0935349 7.71211 -0.00096196 7.45455 0.00276816C7.19698 0.00649827 6.94063 0.108455 6.74654 0.307394L6.7148 0.340965C4.57369 2.51313 2.42525 4.68281 0.287797 6.86119C0.099809 7.05143 0.00337344 7.3001 -0.000288655 7.55002C-0.00395075 7.79993 0.0851606 8.05109 0.269487 8.24755L0.287797 8.2662C0.480668 8.46141 0.734575 8.56212 0.990922 8.56585C1.24727 8.56958 1.50606 8.47508 1.70503 8.28236L7.4753 2.41117L13.2944 8.28236ZM13.0503 14.7193C13.248 14.9108 13.5056 15.004 13.7619 15.0003C14.0195 14.9966 14.2746 14.8958 14.4687 14.6969L14.4834 14.682C14.6665 14.4868 14.7556 14.2344 14.7531 13.9844C14.7495 13.7345 14.653 13.4846 14.4638 13.2944C12.3532 11.1446 10.278 8.92394 8.19307 6.74556C8.18697 6.73685 8.17964 6.72815 8.17232 6.71945C7.97334 6.52672 7.71334 6.43223 7.45577 6.43596C7.1982 6.43969 6.94185 6.54164 6.74776 6.74058C4.67867 8.91524 2.6169 11.1011 0.550249 13.2807L0.53438 13.2956C0.346391 13.4859 0.251176 13.7345 0.247514 13.9844C0.243852 14.2344 0.332964 14.4855 0.516069 14.682L0.53438 14.7006C0.727251 14.8958 0.982378 14.9966 1.2375 15.0003C1.49385 15.004 1.75264 14.9095 1.95162 14.7168L7.47408 8.85058L13.0503 14.7193Z" fill="white"/>
      </svg>
    </button>
    <ModalCommand ref="modalComponent" />  
  </v-container>
</template>

<script>
// import { bus } from "../main";
import SelectionChips from "./SelectionChips.vue";
import ProductList from "./ProductList.vue";
import SuggestedProductList from "./SuggestedProductList.vue";
import MostSearchedList from "./MostSearchedList.vue";
import ModalCommand from './ModalCommand.vue';

export default {
  name: "Home",
  components: {
    SelectionChips,
    ProductList,
    SuggestedProductList,
    MostSearchedList,
    ModalCommand,
  },
  data: () => ({
    selectedTag: null,
    productIds: null,
    productData: null,
    preFillData: null,
    drawer: false,
    group: null,
  }),
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    onGetSelectedTag(value) {
      this.selectedTag = value;
    },
    onGetProductIds(data, productData) {
      this.productIds = data;
      this.productData = productData;
    },
    onSetPreFillData(data) {
      this.preFillData = data;
    },
    handleModal() {
            // Show the modal by accessing the ModalCommand component via a ref
            this.$refs.modalComponent.showModal = true;
    }
  },
};
</script>
<style scoped>
#scroll {
  position: fixed;
    bottom: 91px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    background: #f3283d;
    min-width: 40px;
    min-height: 40px;
    overflow: hidden;
    display: none;
    text-align: center;
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
    transform: scale(1);
    padding: 10px 0;
    border-radius: 4px;
    opacity: 0.8;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ssp-scroll{
  min-height: 650px;
}
</style>